
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "password-reset-email",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    document.title = translate("RESET_PASSWORD_PAGE_TITLE")

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .email(translate("VALID_EMAIL"))
        .required(translate("EMAIL_REQUIRED"))
        .label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: translate(
                "FEEDBACK_PASSWORD_RESET_MAIL_SENDING_SUCCESSFUL"
              ),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: translate("FEEDBACK_PASSWORD_RESET_FAIL"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: translate("TRY_AGAIN_SUBMIT_BUTTON"),
              customClass: {
                confirmButton: "btn fw-bold btn-dark-blue",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      translate,
    };
  },
});
